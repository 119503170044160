/** @jsx jsx */
import { arrayOf, shape, string } from 'prop-types'
import { Container, Heading, jsx } from 'theme-ui'

import renderRichText from '~/content/renderRichText'
import FaqItem from '~/components/FaqItem'
import Section from '~/components/Section'
import { ContentfulRichTextPropType } from '~/types'

const DISPLAY_NAME = 'FaqSection'

const PROP_TYPES = {
  body: ContentfulRichTextPropType.isRequired,
  elementId: string,
  heading: string.isRequired,
  questions: arrayOf(shape({
    question: string,
    answer: ContentfulRichTextPropType.isRequired,
  })).isRequired,
}

const Component = ({
  body,
  elementId,
  heading,
  questions,
}) => (
  <Section id={elementId}>
    <Container>
      <Heading
        sx={{
          variant: 'text.heading1',
          marginBottom: 1,
        }}
      >
        {heading}
      </Heading>
      {renderRichText(body, { style: 'prospect' })}
      <div sx={{ marginTop: 2 }}>
        {questions.map(({ answer, id, question, hashNavigationId }) => (
          <FaqItem
            answer={answer}
            hashNavigationId={hashNavigationId}
            id={id}
            key={id}
            question={question}
          />
        ))}
      </div>
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component

/** @jsx jsx */
import { useLocation } from '@reach/router'
import { string } from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { jsx, Text, Button } from 'theme-ui'
import renderRichText from '~/content/renderRichText'
import { ContentfulRichTextPropType } from '~/types'

const DISPLAY_NAME = 'FaqItem'
const COLLAPSED_HEIGHT = 76
const ICON_SIZE = 21
const ICON_LINE_WIDTH = 1

const PROP_TYPES = {
  answer: ContentfulRichTextPropType.isRequired,
  hashNavigationId: string,
  id: string.isRequired,
  question: string.isRequired,
}

const ICON_LINE_COMMON_STYLES = {
  backgroundColor: 'border',
  gridRow: '1 / 2',
  gridColumn: '1 / 2',
}

const Icon = props => (
  <div
    sx={{
      height: ICON_SIZE,
      width: ICON_SIZE,
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
    }}
    {...props}
  >
    <span
      sx={{
        height: ICON_SIZE,
        width: ICON_LINE_WIDTH,
        ...ICON_LINE_COMMON_STYLES,
      }}
    />
    <span
      sx={{
        width: ICON_SIZE,
        height: ICON_LINE_WIDTH,
        ...ICON_LINE_COMMON_STYLES,
      }}
    />
  </div>
)

const Component = ({ id, question, answer, hashNavigationId = '' }) => {
  const { hash } = useLocation()
  const hashNoPound = hash.substring(1)
  const hashMatches = hashNavigationId.toLowerCase() === hashNoPound.toLowerCase()
  const wrapperRef = useRef()

  const [expanded, setExpanded] = useState(hashMatches)
  const [wrapperHeight, setWrapperHeight] = useState(hashMatches ? 'auto' : COLLAPSED_HEIGHT)

  const handleExpandCollapseClick = () => setExpanded(!expanded)

  useEffect(() => {
    const newWrapperHeight = (
      expanded
        ? (wrapperRef?.current?.scrollHeight ?? 'auto')
        : COLLAPSED_HEIGHT
    )
    setWrapperHeight(newWrapperHeight)
  }, [expanded])

  useEffect(() => {
    if (!hashMatches) {
      setExpanded(false)
      return
    }
    setExpanded(true)
    wrapperRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [hashMatches])

  const iconTransform = expanded ? 'rotate(45deg)' : 'rotate(0deg)'

  return (
    <div
      id={hashNavigationId}
      ref={wrapperRef}
      sx={{
        borderBottom: '1px solid',
        borderColor: 'border',
        height: wrapperHeight,
        overflow: 'hidden',
        transition: 'default',
        transitionProperty: 'height',
      }}
    >
      <Button
        aria-controls={id}
        aria-expanded={expanded}
        onClick={handleExpandCollapseClick}
        sx={{
          display: 'block',
          width: '100%',
          cursor: 'pointer',
          textAlign: 'left',
        }}
        variant="unstyled"
      >
        <div
          sx={{
            height: COLLAPSED_HEIGHT,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text variant="prospectBody">
            {question}
          </Text>
          <Icon
            sx={{
              transition: 'default',
              transitionProperty: 'transform',
              transform: iconTransform,
            }}
          />
        </div>
      </Button>
      <div
        id={id}
        sx={{ paddingBottom: 6 }}
        role="region"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={expanded ? 0 : -1}
      >
        {renderRichText(answer, { style: 'normal' })}
      </div>
    </div>
  )
}

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
